<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.pos_permissions') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox id="created_by_user_id" v-model="data.access_pos.invoices_created_by_user_id" disabled
                                 name="created_by_user_id"
                                 value="1"
                >{{ $t('pos_permissions.invoices_for_sessions_that_the_user_has_created') }}
                </b-form-checkbox>

              </div>
              <div class="col-lg-12 mb-5">
                <b-form-checkbox v-model="data.is_other_sessions_return" name="check-button" size="lg" switch>
                  {{ $t('pos_permissions.is_other_sessions_return') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-md-0 mt-5">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox id="user_branches" v-model="data.access_pos.user_stores" name="user_branches"
                                 unchecked-value="0"
                                 value="1"
                >{{ $t('pos_permissions.stores_that_the_user_has_access_to') }}
                </b-form-checkbox>
                <label>{{ $t('pos_permissions.stores') }}</label>
                <multiselect v-model="stores_obj"
                             :class="validation && validation.special_stores ? 'is-invalid' : ''"
                             :multiple="true"
                             :options="stores"
                             :placeholder="$t('pos_permissions.stores')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getMultiSelectStore">
                </multiselect>
                <span v-if="validation && validation.special_stores"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.special_stores[0] }}
                </span>
              </div>
              <div class="col-12">
                <label>{{ $t('pos_permissions.users') }}</label>
                <multiselect v-model="users_obj"
                             :class="validation && validation.special_user ? 'is-invalid' : ''"
                             :multiple="true"
                             :options="users"
                             :placeholder="$t('pos_permissions.users')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getMultiSelectUsers">
                </multiselect>
                <span v-if="validation && validation.special_user"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.special_user[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="reset" @click="save()">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-user",
  data() {
    return {
      mainRoute: 'user/access_pos',
      mainRouteDependency: 'base/dependency',
      user_id: this.$route.params.user_id ? this.$route.params.user_id : null,
      data: {
        special_stores: [],
        special_user: [],
        is_other_sessions_return: true,
        access_pos: {
          invoices_created_by_user_id: 1,
          user_stores: 0,
        },

      },
      validation: null,
      full_name: null,

      stores_obj: null,
      stores: [],
      users: [],
      users_obj: null,


    };
  },
  watch: {},
  methods: {
    save() {
      this.create();
    },

    create() {
      if (this.user_id)
        ApiService.put(this.mainRoute + '/' + this.user_id, {
          ...this.data,
        }).then((response) => {
          this.$router.push({name: 'users.index'})
          this.validation = null;
          this.$successAlert(response.data.message);
        })
            .catch((error) => {
              this.validation = error.response ? error.response.data.errors : null;
            });
    },


    async getData(id) {
      await ApiService.get(`users/${id}`).then((response) => {
        this.full_name = response.data.data.full_name;
        this.data.access_pos = response.data.data.access_pos ? response.data.data.access_pos : {
          invoices_created_by_user_id: 1,
          user_stores: 0,
        };

        this.data.special_stores = response.data.data.special_stores ? response.data.data.special_stores : [];
        this.data.special_user = response.data.data.special_user ? response.data.data.special_user : [];
        this.data.is_other_sessions_return = response.data.data.is_other_sessions_return ? response.data.data.is_other_sessions_return : false;
        if (response.data.data.special_stores)
          this.stores_obj = this.stores.filter((row) => response.data.data.special_stores.includes(row.id));

        if (response.data.data.special_user) {
          this.users_obj = this.users.filter((row) => response.data.data.special_user.includes(row.id));
        }


        if (this.data.access_pos.invoices_created_by_user_id == 0) {
          this.data.access_pos.invoices_created_by_user_id = 1;
        }
      });
    },


    getMultiSelectStore() {
      this.data.special_stores = [];
      this.stores_obj.filter((row) => {
        this.data.special_stores.push(row.id);
      });
    },
    getMultiSelectUsers() {
      this.data.special_user = [];
      this.users_obj.filter((row) => {
        this.data.special_user.push(row.id);
      });
    },

    async getStores() {
      await ApiService.get(this.mainRouteDependency + "/pos-stores-list").then((response) => {
        this.stores = response.data.data;
      });
    },

    getUsers() {
      ApiService.get('base/dependency/users').then((response) => {
        this.users = response.data.data;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("MENU.user_Management"), route: '/users/users'},
      // {title: this.$t("MENU.pos_permissions")},
      // {title: (this.idEditing ? this.$t('update') : this.$t('create'))}
    ]);

    let promise1 = this.getStores();
    Promise.all([promise1]).then(() => {
      if (this.user_id) {
        let promise2 = this.getData(this.user_id);
        Promise.all([promise2]).then(() => {
          this.$store.dispatch(SET_BREADCRUMB, [
            {title: this.$t("MENU.user_Management"), route: '/users/users'},
            {title: this.full_name},
            {title: this.$t("MENU.pos_permissions")},
          ]);
        })
      }
    })

    this.getUsers();

  },
};
</script>


